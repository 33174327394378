import { Box, createTheme, GlobalStyles, ThemeProvider } from '@mui/material';
import { Amplify } from 'aws-amplify';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { colors } from 'theme';
import Router from './Router';

const queryClient = new QueryClient();

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID_CUSTOMER_PORTAL, // UserPool ID
    region: 'us-east-1',
    userPoolWebClientId:
      process.env.REACT_APP_COGNITO_WEB_CLIENT_ID_CUSTOMER_PORTAL, // WebClientId
  },
});

const theme = createTheme({
  typography: {
    body1: {
      fontSize: 14,
    },
  },
  components: {
    MuiFormControl: {
      defaultProps: {
        sx: {
          background: colors.lightGray4,
          borderRadius: '6px',
          display: 'flex',
          justifyContent: 'center',
          height: '70px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        sx: {
          background: colors.darkGreen,
          textTransform: 'none',
          maxHeight: '70px',
          height: '100%',
          '&:hover': {
            background: colors.darkGreen,
          },
          '&:disabled': {
            background: colors.lightGreen,
          },
        },
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: 'work-sans',
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiInputBase: {
      defaultProps: {
        sx: {
          justifyContent: 'center',
          padding: '15px',
          fontFamily: 'Inter',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        sx: {
          marginRight: 2,
          borderRadius: 8,
          backgroundColor: colors.lightGray2,
          color: '#CCCCCC',
          '&.Mui-selected': {
            backgroundColor: colors.darkBlueGray,
            color: colors.white,
          },
        },
      },
    },
  },
});
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyles styles={{ body: { margin: 0 } }} />
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            minHeight: '100vh',
            padding: { xs: '0px 25px', md: '0' },
          }}
        >
          <Router />
        </Box>
      </ThemeProvider>
      <ToastContainer />
    </QueryClientProvider>
  );
}

export default App;
