import { Box, Button, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { TitleText } from 'components/TitleText';
import { SubtitleText } from 'components/SubtitleText';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { colors } from 'theme';
import { TitledTextField } from 'components/TitledTextField';

interface IProps {
  firstName: string;
}

export const ProfileInformation: FunctionComponent<IProps> = (
  props: IProps,
) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    <Box
      sx={{
        width: { xs: 'auto', sm: '550px' },
        padding: { xs: '25px', sm: '0' },
      }}
    >
      <Box
        sx={{
          display: { xs: 'none', sm: 'flex' },
          flexDirection: 'column',
          marginTop: '30px',
        }}
      >
        <TitleText
          text={`Welcome, ${props.firstName}.`}
          sx={{ textAlign: 'left', marginBottom: '0' }}
        />
        <TitleText
          text="Let's complete your profile."
          sx={{ textAlign: 'left' }}
        />
      </Box>
      <TitleText
        text={`Welcome, ${props.firstName}. Let's complete your profile.`}
        sx={{
          display: { xs: 'flex', sm: 'none' },
          marginBottom: '8px',
          marginTop: '30px',
        }}
      />
      <SubtitleText text="To complete your application, we need to collect information about you and your company for the IRS filing." />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          marginY: '32px',
        }}
      >
        <LockOutlinedIcon
          sx={{ color: colors.darkBlueGray, fontSize: '18px' }}
        />
        <Typography
          fontFamily="work-sans"
          fontSize="14px"
          color={colors.darkBlueGray}
        >
          Secure Connection
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
        <TitledTextField
          title="First Name*"
          width="100%"
          sx={{ marginRight: { xs: '0', sm: '30px' } }}
        />
        <TitledTextField title="Last Name*" width="100%" />
      </Box>
      <TitledTextField title="Email*" width="100%" />
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
        <TitledTextField
          title="Phone Number*"
          width="100%"
          sx={{ marginRight: { xs: '0', sm: '30px' } }}
        />
        <TitledTextField
          title="Extension"
          sx={{ width: { xs: '50%', sm: '170px' } }}
        />
      </Box>
      <TitledTextField title="Job Role*" width="100%" />
      <Box
        sx={{
          width: '150px',
          marginRight: { xs: '0', sm: '20px' },
          marginTop: '48px',
          height: { xs: '50px', sm: '60px' },
        }}
      >
        <Button disabled>
          <Typography
            fontFamily="work-sans"
            fontSize="18px"
            fontWeight="700"
            color={colors.white}
          >
            Continue
          </Typography>
        </Button>
      </Box>
    </Box>
  </Box>
);
