import { Typography, TextField, Box, SxProps } from '@mui/material';
import { FunctionComponent } from 'react';
import { colors } from '../../theme';

interface IProps {
  title: string;
  width?: string;
  sx?: SxProps;
}

export const TitledTextField: FunctionComponent<IProps> = (props: IProps) => {
  const { title, width, sx } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: width || { xs: '100%', sm: '260px' },
        marginBottom: '12px',
        ...sx,
      }}
    >
      <Typography
        fontFamily="work-sans"
        color={colors.darkBlueGray}
        fontWeight="600"
      >
        {title}
      </Typography>
      <TextField variant="standard" />
    </Box>
  );
};
