import { Box, Grow, Radio, RadioProps } from '@mui/material';
import { FunctionComponent } from 'react';
import { colors } from 'theme';
import CheckIcon from '@mui/icons-material/Check';

export const SquareRadio: FunctionComponent<RadioProps> = (
  props: RadioProps,
) => (
  <Radio
    sx={{
      '&:hover': {
        bgcolor: 'transparent',
      },
    }}
    disableRipple
    color="default"
    checkedIcon={
      <Box
        sx={{
          width: '30px',
          height: '30px',
          borderRadius: '6px',
          background: colors.lightGray2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grow in>
          <CheckIcon sx={{ color: colors.darkGreen }} />
        </Grow>
      </Box>
    }
    icon={
      <Box
        sx={{
          width: '30px',
          height: '30px',
          borderRadius: '6px',
          background: colors.lightGray2,
        }}
      />
    }
    {...props}
  />
);
