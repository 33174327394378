import { Box, Tab, Tabs, Typography } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { colors } from 'theme';
import { UploadFileBox } from './UploadFileBox';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tab-${index}`}
      aria-labelledby={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingY: 4 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              width: { md: '820px' },
            }}
          >
            {children}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const Quarterly941YearSelection: FunctionComponent = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  const a11yProps = (index: number) => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  });

  return (
    <Box
      sx={{
        marginTop: 6,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography
        sx={{
          color: colors.darkBlueGray,
          marginBottom: 4,
          fontSize: '18px',
          fontFamily: 'work-sans',
          fontWeight: '700',
        }}
      >
        Please upload your documents
      </Typography>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        aria-label="tab year selector"
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
      >
        <Tab label="2019" {...a11yProps(0)} />
        <Tab label="2020" {...a11yProps(1)} />
        <Tab label="2021" {...a11yProps(2)} />
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        <UploadFileBox title="2019 Q2 941" />
        <UploadFileBox title="2019 Q3 941" />
        <UploadFileBox title="2019 Q3 941" />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <UploadFileBox title="2020 Q1 941" />
        <UploadFileBox title="2020 Q2 941" />
        <UploadFileBox title="2020 Q3 941" />
        <UploadFileBox title="2020 Q4 941" />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <UploadFileBox title="2021 Q1 941" />
        <UploadFileBox title="2021 Q2 941" />
        <UploadFileBox title="2021 Q3 941" />
        <UploadFileBox title="2021 Q4 941" />
      </TabPanel>
    </Box>
  );
};
