export const colors = {
  accentGreen: '#47BE97',
  darkBlueGray: '#333E5A',
  darkGray: '#5A5A5A',
  darkPurple: '#24205A',
  lightGray: '#DDDDDD',
  lightMint: '#F4FAF8',
  mediumGray: '#8A8A8A',
  mediumGreen: '#118252',
  mediumPurple: '#5F61AB',
  mint: '#23A56E',
  veryDarkGray: '#3D3D3D',
  white: '#f9f9f9',
  darkGreen: '#4C9980',
  lightGreen: '#C3DAD2',
  lightGray2: '#F5F5F5',
  lightGray3: '#7C7C7C',
  lightGray4: '#FBFBFB',
  lightGray5: '#9e9e9e',
  black: '#000000',
};
