import { Box } from '@mui/material';
import { AccoutingSelector } from 'components/AccountingSelector/AccountingSelector';
import { Quarterly941YearSelection } from 'components/Quarterly941FileUpload';
import { FileUploadToast } from 'components/Toast';
import { useState } from 'react';
import QuarterlyPayrollText from './QuaterlyPayrollText';

export const QuarterlyPayroll = () => {
  const [isManualUpload, setManualUpload] = useState<boolean>(false);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {isManualUpload && (
        <FileUploadToast setManualUploadFalse={() => setManualUpload(false)} />
      )}
      <Box
        sx={{
          alignItems: 'start',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <QuarterlyPayrollText />
        {isManualUpload ? (
          <Quarterly941YearSelection />
        ) : (
          <AccoutingSelector setManualUpload={() => setManualUpload(true)} />
        )}
      </Box>
    </div>
  );
};
