import { RadioGroup, FormControlLabel, Typography, Box } from '@mui/material';
import { FunctionComponent } from 'react';
import { SquareRadio } from 'components/SquareRadio';
import { colors } from 'theme';

interface IProps {
  title: string;
}

export const YesNoSelect: FunctionComponent<IProps> = (props: IProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Typography
      fontFamily="work-sans"
      fontSize="18px"
      lineHeight="21px"
      color={colors.darkBlueGray}
      fontWeight="700"
      sx={{ marginY: '10px' }}
    >
      {props.title}
    </Typography>
    <RadioGroup row>
      <FormControlLabel
        value="Yes"
        control={<SquareRadio />}
        label={
          <Typography
            fontFamily="work-sans"
            fontSize="18px"
            fontWeight="700"
            color={colors.darkBlueGray}
          >
            Yes
          </Typography>
        }
        sx={{ fontFamily: 'work-sans', fontSize: '18px', marginRight: '50px' }}
      />
      <FormControlLabel
        value="No"
        control={<SquareRadio />}
        label={
          <Typography
            fontFamily="work-sans"
            fontSize="18px"
            fontWeight="700"
            color={colors.darkBlueGray}
          >
            No
          </Typography>
        }
      />
    </RadioGroup>
  </Box>
);
