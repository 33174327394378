import { Box, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { colors } from '../../theme';
import IrLogo from '../../assets/icons/ir-logo.png';
import IrLogoMobile from '../../assets/icons/ir-logo-mobile.png';

export const OnboardingHeader: FunctionComponent = () => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: '110px',
      alignItems: 'center',
    }}
  >
    <Box
      sx={{
        marginLeft: { xs: '0', sm: '40px' },
        visibility: { xs: 'hidden', sm: 'visible' },
        position: { xs: 'absolute', sm: 'static' },
        width: { xs: '0px', sm: 'auto' },
      }}
    >
      <img src={IrLogo} alt="Innovation Refunds logo" height="50px" />
    </Box>
    <Box
      sx={{
        visibility: { xs: 'visible', sm: 'hidden' },
      }}
    >
      <img src={IrLogoMobile} alt="Innovation Refunds logo" height="50px" />
    </Box>
    <Typography
      fontFamily="work-sans, sans-serif"
      fontSize="18px"
      fontWeight={700}
      sx={{ marginRight: { xs: '0', sm: '40px' } }}
      color={colors.darkBlueGray}
    >
      Having Trouble?{' '}
      <span style={{ color: colors.darkGreen, cursor: 'pointer' }}>
        Get Help{' '}
      </span>
    </Typography>
  </Box>
);
