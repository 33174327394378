import { Button, Dialog, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FunctionComponent } from 'react';
import { colors } from '../../theme';

interface AccountingDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  setManualUpload: () => void;
}
export const AccountingDialog: FunctionComponent<AccountingDialogProps> = ({
  isOpen,
  handleClose,
  setManualUpload,
}) => (
  <>
    <Dialog open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: colors.darkBlueGray,
          padding: 6,
          maxWidth: '380px',
        }}
      >
        <Typography
          sx={{
            color: colors.white,
            fontFamily: 'work-sans, sans-serif',
            fontSize: '32px',
            fontWeight: '700',
            marginBottom: 4,
          }}
        >
          WARNING!
        </Typography>
        <Typography
          sx={{
            fontFamily: 'work-sans, sans-serif',
            color: '#EBEBEB',
            fontSize: '16px',
            marginBottom: 4,
          }}
        >
          uploading your files manually may increase your applications
          processing time significantly. It is recommended to integrate your
          software to speed up the application process.
        </Typography>
        <Button
          sx={{
            color: colors.lightGray2,
            backgroundColor: colors.accentGreen,
            width: '264px',
            marginBottom: 4,
            fontFamily: 'work-sans, sans-serif',
          }}
        >
          Connect My software
        </Button>
        <Button
          sx={{ textAlign: 'start', width: '190px' }}
          onClick={() => {
            setManualUpload();
            handleClose();
          }}
        >
          <Typography
            sx={{
              textTransform: 'none',
              color: colors.accentGreen,
              fontSize: '16px',
              fontFamily: 'work-sans, sans-serif',
            }}
          >
            Upload files manually
          </Typography>
        </Button>
      </Box>
    </Dialog>
  </>
);

export default AccountingDialog;
