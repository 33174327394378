import { Search } from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { FunctionComponent, useState } from 'react';
import { colors } from '../../theme';
import AccountingDialog from './AccountingDialog';
import CodatCard from './CodatCard';

interface IProps {
  setManualUpload: () => void;
}

export const AccoutingSelector: FunctionComponent<IProps> = ({
  setManualUpload,
}) => {
  const [UploadFilesDialog, setUploadFilesDialog] = useState<boolean>(false);

  const handleClose = () => {
    setUploadFilesDialog(false);
  };
  return (
    <>
      <Box
        sx={{
          marginTop: 12,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 2,
        }}
      >
        <Typography
          sx={{ size: '18px', fontWeight: '700' }}
          color={colors.darkBlueGray}
          fontFamily="work-sans, sans-serif"
        >
          Connect your accouting software
        </Typography>
        <TextField
          sx={{
            backgroundColor: colors.lightGray2,
            input: {
              color: colors.lightGray3,
              fontFamily: 'work-sans, sans-serif',
            },
          }}
          label="Search ..."
          InputLabelProps={{
            sx: {
              fontFamily: 'work-sans, sans-serif',
              color: colors.lightGray3,
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          padding: 2,
          borderWidth: '1px',
          borderColor: colors.lightGray,
          borderRadius: '6px',
          marginY: 2,
        }}
      >
        <Grid
          container
          spacing={3}
          xs={2}
          sm={6}
          md={8}
          style={{ maxWidth: '800px' }}
          columns={{ xs: 2, sm: 6, md: 8 }}
        >
          <Grid item>
            <CodatCard image="/accountingImages/bambooHR.png" />
          </Grid>
          <Grid item>
            <CodatCard image="/accountingImages/bob.png" />
          </Grid>
          <Grid item>
            <CodatCard image="/accountingImages/gusto.png" />
          </Grid>
          <Grid item>
            <CodatCard image="/accountingImages/insperity.png" />
          </Grid>
          <Grid item>
            <CodatCard image="/accountingImages/justworks.png" />
          </Grid>
          <Grid item>
            <CodatCard image="/accountingImages/namely.png" />
          </Grid>
          <Grid item>
            <CodatCard image="/accountingImages/paylocity.png" />
          </Grid>
        </Grid>
      </Box>
      <Box flexDirection="row" display="flex" alignItems="center">
        <Typography
          sx={{ fontSize: '16px', marginRight: 2 }}
          fontFamily="work-sans, sans-serif"
        >
          Don’t see your software here?
        </Typography>
        <Button
          onClick={() => setUploadFilesDialog(true)}
          sx={{}}
          fullWidth={false}
        >
          <Typography
            sx={{
              textTransform: 'none',
              color: colors.accentGreen,
              fontSize: '16px',
              fontFamily: 'work-sans, sans-serif',
            }}
          >
            Upload files manually
          </Typography>
        </Button>
      </Box>
      <AccountingDialog
        isOpen={UploadFilesDialog}
        handleClose={handleClose}
        setManualUpload={setManualUpload}
      />
    </>
  );
};

export default AccoutingSelector;
