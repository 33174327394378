import { Paper } from '@mui/material';
import { FunctionComponent } from 'react';
import { colors } from '../../theme';

interface CodatCardProps {
  image: string;
}
export const CodatCard: FunctionComponent<CodatCardProps> = ({ image }) => (
  <Paper
    sx={{
      height: '80px',
      width: '240px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.lightGray2,
    }}
  >
    <img
      style={{
        height: '40px',
        width: '160px',
        backgroundColor: 'white',
        mixBlendMode: 'multiply',
      }}
      src={image}
    />
  </Paper>
);

export default CodatCard;
