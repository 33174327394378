import { CompanyInformation } from 'pages/CompanyInformation';
import { Prequalify } from 'pages/Prequalify';
import { ProfileInformation } from 'pages/ProfileInformation';
import { QuarterlyPayroll } from 'pages/QuarterlyPayroll';
import { FunctionComponent, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const QUARTERLY_PAYROLL = 'quarterly-payroll';
const PREQUALIFY = 'prequalify';
const COMPANY_INFORMATION = 'company-information';
const PROFILE_INFORMATION = 'profile-information';

export const PATHS = {
  PREQUALIFY: ['', PREQUALIFY].join('/'),
  QUARTERLY_PAYROLL: ['', QUARTERLY_PAYROLL].join('/'),
  COMPANY_INFORMATION: ['', COMPANY_INFORMATION].join('/'),
  PROFILE_INFORMATION: ['', PROFILE_INFORMATION].join('/'),
};

const AppRoutes: FunctionComponent = () => (
  <Suspense fallback={<div />}>
    <Routes>
      <>
        <Route path={QUARTERLY_PAYROLL}>
          <Route index element={<QuarterlyPayroll />} />
        </Route>
        <Route path={PREQUALIFY}>
          <Route index element={<Prequalify />} />
        </Route>
        <Route path={COMPANY_INFORMATION} element={<CompanyInformation />} />
        <Route
          path={PROFILE_INFORMATION}
          element={<ProfileInformation firstName="John" />}
        />
        <Route path="*" element={<Prequalify />} />
      </>
    </Routes>
  </Suspense>
);

const Router: FunctionComponent = () => (
  <BrowserRouter>
    <AppRoutes />
  </BrowserRouter>
);

export default Router;
