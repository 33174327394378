import { Box, Button, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { TitledTextField } from 'components/TitledTextField';
import { YesNoSelect } from 'components/YesNoSelect';
import { TitleText } from 'components/TitleText';
import { SubtitleText } from 'components/SubtitleText';
import { colors } from '../../theme';

export const CompanyInformation: FunctionComponent = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: { xs: '25px', sm: '0' },
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '800px',
        marginTop: '2vh',
        marginBottom: '64px',
        alignItems: 'start',
      }}
    >
      <TitleText text="What Company is this Application for?" />
      <SubtitleText
        text="  Your company information below is used for filing the application with
        the IRS and calculating your refund credit accurately."
      />
      <Box sx={{ marginTop: { xs: '0', sm: '60px' } }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            marginY: '15px',
          }}
        >
          <LockOutlinedIcon
            sx={{ color: colors.darkBlueGray, fontSize: '18px' }}
          />
          <Typography
            fontFamily="work-sans"
            fontSize="14px"
            color={colors.darkBlueGray}
          >
            Secure Connection
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: { xs: '100%', sm: '550px' },
          }}
        >
          <TitledTextField title="Legal company name*" width="100%" />
          <Box
            sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}
          >
            <TitledTextField
              title="Trade Name DBA*"
              width="100%"
              sx={{ marginRight: { xs: '0', sm: '30px' } }}
            />
            <TitledTextField title="Industry*" width="100%" />
          </Box>
          <Box
            sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}
          >
            <TitledTextField
              title="Approximate Annual Income*"
              width="100%"
              sx={{ marginRight: { xs: '0', sm: '30px' } }}
            />
            <TitledTextField title="Entity Filing Type" width="100%" />
          </Box>
          <TitledTextField title="Website URL*" width="100%" />
          <TitledTextField title="Address Line 1*" width="100%" />
          <TitledTextField title="Address Line 2" width="100%" />
          <Box
            sx={{ display: 'flex', flexDirection: 'row', marginBottom: '30px' }}
          >
            <TitledTextField
              title="City*"
              width="100%"
              sx={{ marginRight: { xs: '8px', sm: '30px' } }}
            />
            <TitledTextField
              title="State*"
              width="100%"
              sx={{ marginRight: { xs: '8px', sm: '30px' } }}
            />
            <TitledTextField title="Zip*" width="100%" />
          </Box>
          <Typography
            fontFamily="work-sans"
            fontSize="18px"
            color={colors.darkBlueGray}
            fontWeight="700"
          >
            Average Number of Full-Time Employees Per Year
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '30px',
              marginTop: '10px',
            }}
          >
            <TitledTextField
              title="2019*"
              width="100%"
              sx={{ marginRight: { xs: '8px', sm: '30px' } }}
            />
            <TitledTextField
              title="2020"
              width="100%"
              sx={{ marginRight: { xs: '8px', sm: '30px' } }}
            />
            <TitledTextField title="2021" width="100%" />
          </Box>
          <Typography
            fontFamily="work-sans"
            fontSize="18px"
            color={colors.darkBlueGray}
            fontWeight="700"
          >
            Average Number of Part-Time Employees Per Year
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '30px',
              marginTop: '10px',
            }}
          >
            <TitledTextField
              title="2019*"
              width="100%"
              sx={{ marginRight: { xs: '8px', sm: '30px' } }}
            />
            <TitledTextField
              title="2020"
              width="100%"
              sx={{ marginRight: { xs: '8px', sm: '30px' } }}
            />
            <TitledTextField title="2021" width="100%" />
          </Box>
          <YesNoSelect title="At any point during 2020 or 2021 did you utilize a PEO (professional employer organization) as your payroll provider?*" />
          <YesNoSelect title="Does your company or its partners have 80% or more ownership in any other businesses?*" />
          <YesNoSelect title="Have you qualified for and claimed any federal government incentives for tax credits within the past three years?*" />
          <YesNoSelect title="Does Your Company Employee Family on Payroll?" />
          <YesNoSelect title="Over the last 4 years has the business developed, designed, evaluated, enhanced, or improved any component, product, service, or process?*" />
          <YesNoSelect title="Have you sold your business recently?" />
          {/* TODO add icon w/ hover text */}
          <YesNoSelect title="Do you use a PEO?" />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            marginTop: '65px',
          }}
        >
          {/* TODO add logic for disabling/enabling button */}
          <Box sx={{ width: '100%', marginRight: '20px' }}>
            <Button disabled>
              <Typography
                fontFamily="work-sans"
                fontSize="18px"
                fontWeight="700"
                color={colors.white}
              >
                Continue
              </Typography>
            </Button>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Button
              sx={{
                background: colors.white,
                textTransform: 'none',
                height: '70px',
              }}
            >
              <Typography
                fontFamily="work-sans"
                fontSize="18px"
                fontWeight="700"
                color={colors.lightGray3}
              >
                Back
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
);
