import { SxProps, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { colors } from 'theme';

interface IProps {
  text: string;
  sx?: SxProps;
}

export const TitleText: FunctionComponent<IProps> = (props: IProps) => (
  <Typography
    color={colors.darkBlueGray}
    sx={{
      marginBottom: { xs: '8px', sm: '20px' },
      textAlign: { xs: 'left', sm: 'center' },
      fontSize: { xs: '24px', sm: '32px' },
      fontWeight: { xs: '900', sm: '700' },
      lineHeight: { xs: '29px', sm: '38px' },
      fontFamily: { xs: 'Inter', sm: 'work-sans' },
      ...props.sx,
    }}
  >
    {props.text}
  </Typography>
);
