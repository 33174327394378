import { Box, Button, Typography } from '@mui/material';
import { OnboardingHeader } from 'components/OnboardingHeader';
import { FunctionComponent } from 'react';
import LockOutlinedIcon from '@mui/icons-material/Lock';
import { TitledTextField } from 'components/TitledTextField';
import { colors } from '../../theme';

export const Prequalify: FunctionComponent = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    <OnboardingHeader />
    <Box sx={{ padding: { xs: '25px', sm: '0' } }}>
      <Typography
        fontFamily="work-sans, sans-serif"
        fontWeight="300"
        textAlign="center"
        sx={{
          fontSize: { xs: '14px', sm: '20px' },
          color: { xs: colors.lightGray5, sm: colors.black },
        }}
      >
        Get Paid Back for Keeping Employees on Payroll During COVID
      </Typography>
      <Box sx={{ maxWidth: '800px', marginTop: '2vh' }}>
        <Typography
          fontFamily="Inter"
          textAlign="center"
          fontWeight="900"
          color={colors.darkBlueGray}
          sx={{
            fontSize: { xs: '24px', sm: '40px' },
            lineHeight: { xs: '29px', sm: '48px' },
          }}
        >
          Approximately How Many W2 Employees Did Your Company Have?
        </Typography>
      </Box>
      <Box sx={{ width: { xs: '100%', sm: 'auto' } }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: { xs: 'center', sm: 'end' },
            marginY: '30px',
          }}
        >
          <LockOutlinedIcon
            sx={{ color: colors.darkBlueGray, fontSize: '18px' }}
          />
          <Typography
            fontFamily="work-sans"
            fontSize="14px"
            color={colors.darkBlueGray}
          >
            Secure Connection
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'center',
          }}
        >
          <Box sx={{ marginRight: { xs: '0', sm: '10px' } }}>
            <TitledTextField title="2020 Employees*" />
          </Box>
          <Box sx={{ marginLeft: { xs: '0', sm: '10px' } }}>
            <TitledTextField title="2021 Employees*" />
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: { xs: '48px', sm: '65px' },
          }}
        >
          {/* TODO add logic for disabling/enabling button */}
          <Box sx={{ width: { xs: '275px', sm: '400px' } }}>
            <Button disabled>
              <LockOutlinedIcon
                sx={{ color: colors.white, fontSize: '18px' }}
              />

              <Typography
                fontFamily="work-sans"
                fontSize="18px"
                fontWeight="700"
                color={colors.white}
              >
                Get Pre-Qualified
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  </div>
);
