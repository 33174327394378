import { Box, Button, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { colors } from '../../theme';

interface IProps {
  setManualUploadFalse: () => void;
}
export const FileUploadToast: FunctionComponent<IProps> = ({
  setManualUploadFalse,
}) => (
  <Box
    sx={{
      width: '320px',
      height: '187px',
      border: 'red',
      position: 'absolute',
      right: 0,
      top: 0,
      padding: 2,
      display: { xs: 'none', md: 'flex' },
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignContent: 'space-between',
      background: 'white',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
      boxSizing: 'border-box',
    }}
  >
    <Typography
      sx={{ fontSize: '14px', fontFamily: 'work-sans', fontWeight: '400' }}
    >
      You can create a one-time connection to your accounting software to make a
      file uploading process hassle-free.
    </Typography>
    <Button
      sx={{
        backgroundColor: colors.accentGreen,
        width: '190px',
        height: '40px',
        '&:hover': {
          background: colors.darkGreen,
        },
        '&:disabled': {
          background: colors.lightGreen,
        },
      }}
      onClick={setManualUploadFalse}
    >
      <Typography
        color={colors.white}
        fontSize="14px"
        fontFamily="work-sans"
        fontWeight="700"
      >
        Connect
      </Typography>
    </Button>
  </Box>
);
