import { Box, styled, Typography } from '@mui/material';
import { colors } from '../../theme';

const StyledQuarterlyPayrollText = styled(Typography)({
  color: colors.darkBlueGray,
  fontSize: '16px',
  fontFamily: 'work-sans',
});

const QuarterlyPayrollHeader = styled(Typography)({
  color: colors.darkBlueGray,
  fontSize: '32px',
  fontFamily: 'work-sans, sans-serif',
  fontWeight: '700',
});

export const QuarterlyPayrollText = () => (
  <Box sx={{ width: { xs: '100%', sm: '600px' } }}>
    <QuarterlyPayrollHeader>
      Quarterly 941 Payroll Tax Filings
    </QuarterlyPayrollHeader>
    <StyledQuarterlyPayrollText>
      The first set of data that we need for the Employee Retention Credit
      calculation will be your quarterly IRS Form 941 payroll tax filings. We
      will need these for the specified COVID impact quarterly periods in 2020 &
      2021. You will be able to securely connect to your Payroll carrier (which
      will upload all your data instantly) or you will have the option to upload
      all Form 941 files separately.
    </StyledQuarterlyPayrollText>
  </Box>
);

export default QuarterlyPayrollText;
