import { Download } from '@mui/icons-material';
import { Box, styled, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { colors } from 'theme';

const FileUploadCard = styled(Box)({
  position: 'relative',
  color: 'darkslategray',
  height: '190px',
  backgroundColor: '#EBEBEB',
  borderRadius: 4,
  marginRight: 16,
});
const TitleHeader = styled(Box)({
  height: '50px',
  width: 'full',
  backgroundColor: '#4D5B80',
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  padding: 8,
});

interface IProps {
  title?: string;
}
export const UploadFileBox: FunctionComponent<IProps> = ({ title }) => (
  <FileUploadCard sx={{ width: { xs: '100%', sm: '140px' } }}>
    <TitleHeader
      sx={{
        display: 'flex',
        alignItems: { xs: 'center', sm: 'start' },
      }}
    >
      <Typography
        sx={{
          color: 'white',
          fontWeight: '600',
          fontSize: '14px',
          fontFamily: 'inter',
        }}
        fontFamily="work-sans, sans-serif"
      >
        {title}
      </Typography>
    </TitleHeader>
    <Box padding={2} sx={{ width: 'full' }}>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: '400',
          fontFamily: 'work-sans, sans-serif',
          fontColor: colors.darkGray,
        }}
      >
        Drop your file here to upload or choose file
      </Typography>
    </Box>
    <Box
      padding={0}
      sx={{
        position: 'absolute',
        bottom: '0',
        height: '40px',
        marbin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#FFFFFF',
        flexDirection: 'row',
      }}
    >
      <Download sx={{ color: '#7C7C7C' }} />
      <Typography sx={{ fontSize: '12px', color: '#7C7C7C' }}>
        Preview Example
      </Typography>
    </Box>
  </FileUploadCard>
);
