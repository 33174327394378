import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { colors } from 'theme';

interface IProps {
  text: string;
}

export const SubtitleText: FunctionComponent<IProps> = (props: IProps) => (
  <Typography
    fontFamily="work-sans"
    sx={{
      fontSize: { xs: '14px', sm: '16px' },
      fontWeight: { xs: '700', sm: '400' },
      lineHeight: { xs: '16px', sm: '24px' },
      color: { xs: colors.black, sm: colors.darkBlueGray },
    }}
  >
    {props.text}
  </Typography>
);
